body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a, a:hover, a:active, a:visited {
    text-decoration: none;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.d3-tip-mod {
    pointer-events: none !important;
}